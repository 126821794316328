import { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import axios from 'axios';
import { baseURL } from 'Ressources';
import ImageRenderer from 'asset/ImageRenderer';
import i18n from 'I18n/index';

const CV = () => {
    const [loading, setLoading] = useState(false);
    const [cvData, setCvData] = useState({
        competences: [],
        experiences: [],
        formations: []
    });

    useEffect(() => {
        const fetchCvData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseURL}/cv`);
                if (response.status === 200) {
                    let { competences, experiences, formations } = response.data;

                    // Trier les formations du plus récent au plus ancien
                    formations = formations.sort((a, b) => b.annee_fin - a.annee_fin);
                    experiences = experiences.sort((a, b) => b.annee_fin - a.annee_fin);


                    setCvData({ competences, experiences, formations });
                } else {
                    throw new Error(i18n.t('errors.data.default'));
                }
            } catch (error) {
                message.error(i18n.t('errors.data.default'));
            }
            setLoading(false);
        };

        fetchCvData();
    }, []);

    return (
        <div className="base-container">
            {loading ? (
                <Spin spinning={true} />
            ) : (
                <>
                    <section className="base-section">
                        <h2 className="base-section-header">{i18n.t('title.competence')}</h2>
                        <div className="two-column-container">
                            <div className="left-column">
                                <ul className="base-list">
                                    {cvData.competences.map((competence, index) => (
                                        <li key={index} className="base-item">
                                            <span className="base-item-title">{competence.nom} : </span>
                                            <ImageRenderer imageName={competence.img} alt={competence.nom} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="base-section">
                        <h2 className="base-section-header">{i18n.t('title.experience')}</h2>
                        <div className="two-column-container">
                            <div className="left-column">
                                <ul className="base-list">
                                    {cvData.experiences.map((experience, index) => (
                                        <li key={index} className="base-item">
                                            <span className="base-item-title">{experience.nom} à {experience.lieu}</span>
                                            <br />
                                            <span className="base-item-dates">
                                                {experience.mois_debut} {experience.annee_debut} - {experience.mois_fin === "En cours" ? "En cours" : `${experience.mois_fin} ${experience.annee_fin}`}
                                            </span>                                           
                                            <p className="base-item-description">{experience.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="base-section">
                        <h2 className="base-section-header">{i18n.t('title.formation')}</h2>
                        <div className="two-column-container">
                            <div className="left-column">
                                <ul className="base-list">
                                    {cvData.formations.map((formation, index) => (
                                        <li key={index} className="base-item">
                                            <span className="base-item-title">{formation.titre}, {formation.centre}</span>
                                            <br />
                                            <span className="base-item-dates">{formation.mois_debut} {formation.annee_debut} - {formation.mois_fin === "En cours" ? "En cours" : `${formation.mois_fin} ${formation.annee_fin}`}</span>
                                            <p className="base-item-description">{formation.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                </>
            )}
            {/*<div className="planet planet-1"></div>/*}
            {<div className="planet planet-2"></div>}
            {<div className="planet planet-3"></div>*/}
        </div>
    );
};

export default CV;
