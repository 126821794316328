import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, message, InputNumber } from 'antd';
import useFormationData from 'component/Data/FormationData/useFormationData';
import i18n from 'I18n/index';

const FormationModal = ({ visible, onClose, initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { createFormation, updateFormation } = useFormationData();
    const { TextArea } = Input;

    useEffect(() => {
        form.resetFields();
        if (initialValues) {
            form.setFieldsValue({
                titre: initialValues.titre,
                centre: initialValues.centre,
                type: initialValues.type,
                description: initialValues.description,
                mois_debut: initialValues.mois_debut,
                annee_debut: initialValues.annee_debut,
                mois_fin: initialValues.mois_fin,
                annee_fin: initialValues.annee_fin,
            });
        }
    }, [form, initialValues]);


    const handleFinish = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('titre', values.titre);
        formData.append('centre', values.centre);
        formData.append('type', values.type);
        formData.append('description', values.description);
        formData.append('mois_debut', values.mois_debut);
        formData.append('annee_debut', values.annee_debut);
        formData.append('mois_fin', values.mois_fin);
        formData.append('annee_fin', values.annee_fin);

        try {
            if (initialValues && initialValues.id) {
                await updateFormation(initialValues.id, formData);
            } else {
                await createFormation(formData);
            }
            onClose();
        } catch (error) {
            message.error(i18n.t('error.formation.save'));
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal
            open={visible}
            title={initialValues ? i18n.t('pages.formation.form.edit') : i18n.t('pages.formation.form.add')}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {i18n.t('button.cancel')}
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
                    {initialValues ? i18n.t('button.update') : i18n.t('button.create')}
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item name="id" label="ID" hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="titre"
                    label={i18n.t('pages.formation.table.title')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.title') }]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="centre"
                    label={i18n.t('pages.formation.table.centre')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.centre') }]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="type"
                    label={i18n.t('pages.formation.table.type')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.type') }]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={i18n.t('pages.formation.table.description')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.description') }]}
                    >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="mois_debut"
                    label={i18n.t('pages.formation.table.debutM')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.debutM') }]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="annee_debut"
                    label={i18n.t('pages.formation.table.debutA')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.debutA') }]}
                    >
                    <InputNumber min={2000} max={2100} />
                </Form.Item>
                <Form.Item
                    name="mois_fin"
                    label={i18n.t('pages.formation.table.finM')}
                    rules={[{ required: true, message: i18n.t('pages.formation.placeholder.finM') }]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="annee_fin"
                    label={i18n.t('pages.formation.table.finA')}
                    //rules={[{ required: true, message: i18n.t('pages.formation.placeholder.finA') }]}
                    >
                    <InputNumber min={2000} max={2100} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

FormationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        titre: PropTypes.string,
        centre: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        mois_debut: PropTypes.string,
        annee_debut: PropTypes.string,
        mois_fin: PropTypes.string,
        annee_fin: PropTypes.string,
    }),
};

FormationModal.defaultProps = {
    initialValues: null,
};

export default FormationModal;
